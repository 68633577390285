import _ from "lodash";
import update from "immutability-helper";

import * as actionTypes from "../actions/actions_types";
import initialState from "./initialState";

export default function therapistReducer(
  state = initialState.therapist,
  action
) {
  let newState = state;
  switch (action.type) {
    case actionTypes.PATIENT_SELECTED_SUCCESS:
      return Object.assign({}, state, {
        selectedPatient: action.selectedPatient,
        patientDetails: action.patientDetails,
        ThreeTreatmentPlan: action.patientDetails.treatmentPlans,
      });

    case actionTypes.GET_PATIENTS_INFO_SUCCESS:
      return Object.assign({}, state, {
        knownPatientsDetails: action.patientsDetails,
      });

    case actionTypes.PATIENT_DESELECT:
      return Object.assign({}, state, {
        selectedPatient: null,
        patientDetails: null,
        ThreeTreatmentPlan: null,
      });

    case actionTypes.POLL_FOR_PATIENT_LOGGED_IN_STATUS_SUCCESS:
      return Object.assign({}, state, {
        shouldUpdateLoggedInStatus: action.shouldPoll,
        patientsLoggedInStatus: action.shouldPoll
          ? action.patientsLoggedInAndSessions.patientsLoggedIn
          : state.patientsLoggedInStatus,
        patientsSessions: action.shouldPoll
          ? action.patientsLoggedInAndSessions.patientsSessions
          : state.patientsSessions,
      });

    case actionTypes.SET_PATIENT_LOGGED_IN_STATUS:
      return Object.assign({}, state, {
        patientsLoggedInStatus:
          action.patientsLoggedInAndSessions.patientsLoggedIn,
        patientsSessions: action.patientsLoggedInAndSessions.patientsSessions,
      });

    case actionTypes.RELOAD_TASKS_SUCCESS:
    case actionTypes.CHANGE_TASKS_SUCCESS:
      if (state.patientDetails) {
        newState = update(state, {
          patientDetails: { tasks: { $set: action.tasks } },
        });
      }
      return Object.assign({}, state, newState);

    case actionTypes.SAVE_TASK_SUCCESS:
      newState = update(state, {
        patientDetails: { tasks: { $set: action.tasks } },
      });
      return Object.assign({}, state, newState);

    case actionTypes.THERAPIST_REPORT_SUCCESS:
      return Object.assign({}, state, {
        selectedUser: action.selectedUser,
      });

    case actionTypes.THERAPIST_FINANCE_SUCCESS:
      return Object.assign({}, state, {
        selectedUser: action.selectedUser,
      });

    case actionTypes.CREATE_NEW_APPOINTMENT_SUCCESS:
    case actionTypes.DELETE_APPOINTMENT_SUCCESS:
    case actionTypes.GET_CLINICIAN_UPCOMING_APPOINTMENTS_SUCCESS:
      newState = update(state, {
        upcomingAppointments: { $set: action.upcomingAppointments },
      });
      if (action.patientUpcomingAppointments) {
        newState = update(newState, {
          patientDetails: {
            upcomingAppointments: { $set: action.patientUpcomingAppointments },
          },
        });
      }
      return Object.assign({}, state, newState);

    case actionTypes.TREATMENT_PLAN_INPUT_CHANGE_MODAL:
      if (
        _.isEmpty(
          state.ThreeTreatmentPlan[action.weekIndex][action.dayIndex].treatments
        )
      ) {
        newState = update(state, {
          ThreeTreatmentPlan: {
            [action.weekIndex]: {
              [action.dayIndex]: {
                treatments: { $set: [action.state] },
              },
            },
          },
        });
        return Object.assign({}, state, newState);
      } else if (
        _.isNil(
          state.ThreeTreatmentPlan[action.weekIndex][action.dayIndex]
            .treatments[action.treatmentIndex]
        )
      ) {
        newState = update(state, {
          ThreeTreatmentPlan: {
            [action.weekIndex]: {
              [action.dayIndex]: {
                treatments: { $push: [action.state] },
              },
            },
          },
        });
        return Object.assign({}, state, newState);
      }
      newState = update(state, {
        ThreeTreatmentPlan: {
          [action.weekIndex]: {
            [action.dayIndex]: {
              treatments: {
                [action.treatmentIndex]: { $merge: action.state },
              },
            },
          },
        },
      });
      return Object.assign({}, state, newState);

    case actionTypes.GET_ALL_PATIENTS_TREATMENTS_SUCCESS:
      if (
        state.selectedPatient != null &&
        action.selectedPatientTreatments != null
      ) {
        newState = update(newState, {
          patientDetails: {
            completedTasks: { $set: action.selectedPatientTreatments },
          },
        });
      }
      return Object.assign({}, state, newState);

    case actionTypes.GET_ALL_PATIENTS_SUCCESS:
      newState = update(newState, {
        patientList: { $set: action.patientList },
      });
      return Object.assign({}, state, newState);

    case actionTypes.TREATMENT_PLAN_SUBMIT_SUCCESS:
      const newPlanState = update(state, {
        patientDetails: { treatmentPlans: { $set: action.newTreatmentPlan } },
      });
      return Object.assign({}, state, newPlanState);

    case actionTypes.THERAPIST_GET_COMPLETED_TASKS_SUCCESS:
    case actionTypes.ON_THERPAIST_EXIT_GAME_SUCCESS:
    case actionTypes.THERAPIST_COMPLITED_TASKS_NOTES_DATA_SUCCESS:
      newState = update(state, {
        patientDetails: { completedTasks: { $set: action.sessionLogs } },
      });
      return Object.assign({}, state, newState);

    case actionTypes.THERAPIST_GET_EVALUATIONS_LIST_SUCCESS:
      newState = update(state, {
        patientDetails: { evalLogs: { $set: action.evalLogs } },
      });
      return Object.assign({}, state, newState);

    // error handling :
    case actionTypes.GET_PATIENTS_INFO_ERROR:
    case actionTypes.PATIENT_SELECTED_ERROR:
    case actionTypes.PATIENT_DESELECT_ERROR:
    case actionTypes.TREATMENT_PLAN_SUBMIT_ERROR:
    case actionTypes.THERAPIST_GET_COMPLETED_TASKS_ERROR:
    case actionTypes.THERAPIST_COMPLITED_TASKS_NOTES_DATA_ERROR:
    case actionTypes.GET_CLINICIAN_UPCOMING_APPOINTMENTS_ERROR:
    case actionTypes.CREATE_NEW_APPOINTMENT_ERROR:
    case actionTypes.DELETE_APPOINTMENT_ERROR:
    case actionTypes.POLL_FOR_PATIENT_LOGGED_IN_STATUS_ERROR:
    case actionTypes.GET_ALL_PATIENTS_TREATMENTS_ERROR:
    case actionTypes.GET_ALL_PATIENTS_ERROR:
    case actionTypes.THERAPIST_REPORT_ERROR:
    case actionTypes.THERAPIST_FINANCE_ERROR:
    case actionTypes.THERAPIST_TREATMENT_ERROR:
    case actionTypes.THERAPIST_GET_EVALUATIONS_LIST_ERROR:
      console.log(action.error);
      return Object.assign({}, state, { error: action.error });

    default:
      return state;
  }
}
