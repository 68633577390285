import _ from "lodash";

import * as actionTypes from "../actions/actions_types";
import initialState from "./initialState";

export default function patientReducer(state = initialState.patient, action) {
  switch (action.type) {
    case actionTypes.GET_PATIENT_UPCOMING_APPOINTMENTS_SUCCESS:
      return Object.assign({}, state, {
        upcomingAppointments: action.upcomingAppointments,
      });

    case actionTypes.PATIENT_GET_COMPLETED_TASKS_SUCCESS:
    case actionTypes.ON_PATIENT_EXIT_GAME_SUCCESS:
      return Object.assign({}, state, { sessionLogs: action.sessionLogs });

    case actionTypes.GET_PATIENT_UPCOMING_APPOINTMENTS_ERROR:
    case actionTypes.GET_PATIENT_TREATMENT_PLAN_ERROR:
    case actionTypes.PATIENT_GET_COMPLETED_TASKS_ERROR:
    case actionTypes.ON_EXIT_GAME_ERROR:
      console.log(action.error);
      return Object.assign({}, state, { error: action.error });

    default:
      return state;
  }
}
