import _ from "lodash";

import * as actionTypes from "../actions/actions_types";
import initialState from "./initialState";

export default function superadminReducer(
  state = initialState.superadmin,
  action
) {
  const newState = state;
  switch (action.type) {
    case actionTypes.GET_INVOICES_SUCCESS:
      return Object.assign({}, state, { invoices: action.invoices });

    case actionTypes.POLL_FOR_USERS_LOGGED_IN_STATUS_SUCCESS:
      return Object.assign({}, state, {
        shouldUpdateLoggedInStatus: action.shouldPoll,
      });

    case actionTypes.GET_USER_SESSION_DETAILS:
      return Object.assign({}, state, {
        remoteSessionLogs: action.remoteSessionLogs,
      });

    case actionTypes.SET_LOGGED_IN_USERS:
      if (_.isEqual(action.loggedInUsers, state?.loggedInUsers)) {
        return state;
      }
      return Object.assign({}, state, { loggedInUsers: action.loggedInUsers });

    case actionTypes.SUPERADMIN_GET_TRANSLATION_DATA_SUCCESS:
      return Object.assign({}, state, {
        translationData: action.translationData,
      });

    case actionTypes.GET_INVOICES_ERROR:
    case actionTypes.SUPERADMIN_GET_TRANSLATION_DATA_ERROR:
      /* error catcher*/
      console.log(action.error);
      return Object.assign({}, state, { error: action.error });

    default:
      return state;
  }
}
