/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  api: {},
  login: {
    errors: "",
    user: null,
    serverConnectionStatus: true,
    games: [],
  },
  wizard: {
    currentPage: null,
    inActivity: false,
    selectedGame: null,
    selectedDevice: null,
    selectedJoint: null,
    selectedMotion: null,
    secondaryJoint: null,
    secondaryMotion: null,
    is2D: false,
    serviceStatus: null,
    serviceShouldUpdate: false,
    bluetoothDevices: [],
    connectedDevices: [],
    calibratedDevices: [],
    multiSensorDevicesCalibration: [],
    gameInProgress: null,
    gameMove: 0,
    error: "",
  },
  evaluation: {
    inActivity: false,
    evaluationMotionAnalysisState: "Stopped",
    selectedDevice: null,
    evaluationType: null,
    evaluationJoint: null,
  },
  therapist: {
    selectedPatient: null,
    shouldUpdateLoggedInStatus: false,
    patientsLoggedInStatus: {},
    patientsSessions: [],
    patientList: undefined,
  },
  patient: {},
  remote: {
    callStatus: null,
    session: null,
  },
  superadmin: {},
  app: {
    serviceDownloadPopUp: false,
    sidebar: true,
  },
};
