import * as actionTypes from "../actions/actions_types";
import initialState from "./initialState";

export default function appReducer(state = initialState.app, action) {
  const newState = state;
  switch (action.type) {
    case actionTypes.SHOW_SERVICE_DOWNLOAD_POPUP:
      return Object.assign({}, state, {
        serviceDownloadPopUp: action.status,
      });
    
    case actionTypes.SIDE_BAR:
      return Object.assign({}, state, {
        sidebar: action.status,
      });

    default:
      return state;
  }
}
