const Logger = {};

let eventLogs = [];

const doLog = (
  severity,
  logName,
  logMessage,
  stack,
  additionalInfoType,
  additionalInfo
) => {
  const newLog = {
    severity,
    userDate: new Date(),
    logName,
    logMessage,
    stack,
    additionalInfoType: additionalInfoType == null ? null : additionalInfoType,
    additionalInfo: additionalInfo == null ? null : additionalInfo,
  };
  eventLogs = eventLogs.concat([newLog]);
};

Logger.logDebug = (logName, logMessage, additionalInfoType, additionalInfo) => {
  // console.log('DEBUG:' + logName + ": " + logMessage);
  doLog("DEBUG", logName, logMessage, "", additionalInfoType, additionalInfo);
};

Logger.logInfo = (logName, logMessage, additionalInfoType, additionalInfo) => {
  console.log(`${"%c INFO:"}${logName}: ${logMessage}`, "font-weight: bold;");
  doLog("INFO", logName, logMessage, "", additionalInfoType, additionalInfo);
};

Logger.logWarn = (logName, logMessage, additionalInfoType, additionalInfo) => {
  console.log(
    `${"%c WARN:"}${logName}: ${logMessage}`,
    "color:orange; font-weight: bold;"
  );
  doLog("WARN", logName, logMessage, "", additionalInfoType, additionalInfo);
};

Logger.logError = (
  logName,
  logMessage,
  stack,
  additionalInfoType,
  additionalInfo
) => {
  console.log(`${"%c ERROR:"}${logName}: ${logMessage}`, "color:red;");
  doLog(
    "ERROR",
    logName,
    logMessage,
    stack,
    additionalInfoType,
    additionalInfo
  );
};

Logger.logFatal = (
  logName,
  logMessage,
  stack,
  additionalInfoType,
  additionalInfo
) => {
  console.log(
    `${"%c FATAL:"}${logName}: ${logMessage}`,
    "color:red; font-weight: bold;"
  );
  doLog(
    "FATAL",
    logName,
    logMessage,
    stack,
    additionalInfoType,
    additionalInfo
  );
};

Logger.logDebugFormat = (
  logName,
  logMessage,
  format,
  additionalInfoType,
  additionalInfo
) => {
  console.log(`${"%c DEBUG:"}${logName}: ${logMessage}`, format);
  doLog("DEBUG", logName, logMessage, "", additionalInfoType, additionalInfo);
};

Logger.logInfoFormat = (
  logName,
  logMessage,
  format,
  additionalInfoType,
  additionalInfo
) => {
  console.log(`${"%c INFO:"}${logName}: ${logMessage}`, format);
  doLog("INFO", logName, logMessage, "", additionalInfoType, additionalInfo);
};

Logger.logWarnFormat = (
  logName,
  logMessage,
  format,
  additionalInfoType,
  additionalInfo
) => {
  console.log(`${"%c WARN:"}${logName}: ${logMessage}`, format);
  doLog("WARN", logName, logMessage, "", additionalInfoType, additionalInfo);
};

Logger.logErrorFormat = (
  logName,
  logMessage,
  format,
  stack,
  additionalInfoType,
  additionalInfo
) => {
  console.log(`${"%c ERROR:"}${logName}: ${logMessage}`, format);
  doLog(
    "ERROR",
    logName,
    logMessage,
    stack,
    additionalInfoType,
    additionalInfo
  );
};

Logger.logFatalFormat = (
  logName,
  logMessage,
  format,
  stack,
  additionalInfoType,
  additionalInfo
) => {
  console.log(`${"%c FATAL:"}${logName}: ${logMessage}`, format);
  doLog(
    "FATAL",
    logName,
    logMessage,
    stack,
    additionalInfoType,
    additionalInfo
  );
};

export const logsAcknowledged = (logTime) => {
  const logs = eventLogs.filter((log) => log.userDate.getTime() > logTime);
  eventLogs = logs;
};

export const getLastLogs = () => {
  return eventLogs;
};

export default Logger;
