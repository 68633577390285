import GitInfo from "react-git-info/macro";
import moment from "moment";
const BuildVersion = () => {
  return (
    <div className="version_number" id="buildVersion">
      <div className="version_number_text">
        {moment(GitInfo().commit.date).format("YYYY-MM-DD HH:mm")}
      </div>
      <div className="version_number_text">{GitInfo().commit.shortHash}</div>
    </div>
  );
};
export default BuildVersion;
