import * as actionTypes from "../actions/actions_types";
import initialState from "./initialState";
import update from "immutability-helper";
import { history } from "../history";

export default function loginReducer(state = initialState.login, action) {
  let newState = state;
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS_PATIENT:
    case actionTypes.LOGIN_SUCCESS_THERAPIST:
    case actionTypes.LOGIN_SUCCESS_SITEADMIN:
    case actionTypes.LOGIN_SUCCESS_ADMIN:
    case actionTypes.LOGIN_SUCCESS_SUPERADMIN:
      action.user.errors = "";
      action.user.subUserDetails = {};
      return Object.assign({}, state, {
        errors: "",
        inLogin: false,
        user: action.user,
      });

    case actionTypes.GET_USER_DETAILS_SUCCESS:
    case actionTypes.UPDATE_SELF_SUCCESS:
      action.user.subUserDetails = state.user.subUserDetails;
      return Object.assign({}, state, { user: action.user });

    case actionTypes.LOGOUT:
      return state;
    // return Object.assign({}, state, { errors: '', user: null });

    case actionTypes.DECLARE_IN_LOGIN_PROCESS:
      return Object.assign({}, state, { errors: "", inLogin: true });

    case actionTypes.SERVER_CONNECTION_STATUS:
      return Object.assign({}, state, {
        errors: "",
        serverConnectionStatus: action.status,
        path: history.location.pathname,
      });

    case actionTypes.RELOAD_TASKS_SUCCESS:
    case actionTypes.CHANGE_TASKS_SUCCESS:
      if (state.user.role === "patient") {
        newState = update(state, {
          user: {
            patientDetails: {
              tasks: { $set: action.tasks },
            },
          },
        });
      }
      return Object.assign({}, state, newState);

    case actionTypes.GET_PATIENT_TREATMENT_PLAN_SUCCESS: // TODO: treatmentPlan should be in patient reducer, or anywhere not here
      newState = update(state, {
        user: {
          patientDetails: {
            treatmentPlan: { $set: action.treatmentPlan },
          },
        },
      });
      return Object.assign({}, state, newState);

    case actionTypes.ON_PATIENT_EXIT_GAME_SUCCESS:
      newState = update(state, {
        user: {
          patientDetails: {
            treatmentPlan: { $set: action.treatmentPlan },
            sessionLogs: { $set: action.sessionLogs },
          },
        },
      });
      return Object.assign({}, state, newState);

    case actionTypes.GET_SUB_USERS_SUCCESS:
      newState = update(state, {
        user: { subUserDetails: { $set: action.subUsersDetails } },
      });
      return Object.assign({}, state, newState);

    case actionTypes.GET_SUB_USER_DETAILS_SUCCESS:
      newState = update(state, {
        user: { subUserToEdit: { $set: action.subUserDetails } },
      });
      return Object.assign({}, state, newState);

    case actionTypes.INVALID_SUB_USER_TO_EDIT:
      newState = update(state, { user: { subUserToEdit: { $set: null } } });
      return Object.assign({}, state, newState);

    case actionTypes.CHANGE_USER_SUCCESS:
      switch (action.actionRequesterRole) {
        case "superadmin":
          newState = update(state, {
            user: { superAdminDetails: { $set: action.userDetails } },
          });
          break;
        case "admin":
          newState = update(state, {
            user: { adminDetails: { $set: action.userDetails } },
          });
          break;
        case "siteadmin":
          newState = update(state, {
            user: { siteDetails: { $set: action.userDetails } },
          });
          break;
        case "supervisor":
        case "therapist":
          newState = update(state, {
            user: { therapistDetails: { $set: action.userDetails } },
          });
          break;
      }
      return Object.assign({}, state, newState);

    case actionTypes.GET_ALL_PATIENTS_TREATMENTS_SUCCESS:
      newState = update(state, {
        user: { therapistDetails: { $set: action.userDetails } },
      });
      return Object.assign({}, state, newState);

    case actionTypes.TREATMENT_PLAN_SUBMIT_SUCCESS:
      newState = update(state, {
        user: {
          therapistDetails: { patientList: { $set: action.patientList } },
        },
      });
      return Object.assign({}, state, newState);

    case actionTypes.GET_GAMES_SETTINGS_SUCCESS:
      newState = update(newState, { games: { $set: action.games } });
      newState = update(newState, { opticalGames: { $set: action.opticalGames } });
      newState = update(newState, { opticalGroups: { $set: action.opticalGroups } });
      return Object.assign({}, state, newState);

    case actionTypes.GET_GAMES_SETTINGS_ERROR:
    case actionTypes.GET_USER_DETAILS_ERROR:
    case actionTypes.GET_SUB_USERS_ERROR:
    case actionTypes.GET_SUB_USER_DETAILS_ERROR:
    case actionTypes.CHANGE_USER_ERROR:
    case actionTypes.UPDATE_SELF_ERROR:
      // console.log(action.error);
      return Object.assign({}, state, { errors: action.error });

    case actionTypes.LOGIN_ERROR:
      console.log(action.error);
      return Object.assign({}, state, {
        errors: action.error,
        inLogin: false,
        user: null,
      });

    default:
      return state;
  }
}
