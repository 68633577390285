import { combineReducers } from "redux";

import api from "./api_reducer";
import login from "./login_reducer";
import wizard from "./wizard_reducer";
import therapist from "./therapist_reducer";
import patient from "./patient_reducer";
import evaluation from "./evaluation_reducer";
import remote from "./remote_reducer";
import superadmin from "./superadmin_reducer";
import app from "./app_reducer";

const rootReducer = combineReducers({
  api,
  login,
  wizard,
  therapist,
  patient,
  evaluation,
  remote,
  superadmin,
  app,
});

export default rootReducer;
