import cookie from "react-cookie";

const ajaxUtil = {};

ajaxUtil.getJWTToken = () => cookie.load("jwt_token");

/*
* ajaxUtil.genRequest Create a request object for axios

* req.params - axios will appended to the url.
* req.headers - Authorization field will be set with jtw_token (if found at cookies AND is undefined)
*
* at the server side you will see params under the req object as follows:
* req.params -> req.query
* req.headers -> req.headers
* req.data -> found under req.body (only for POST/DELETE/PUT)
*/
ajaxUtil.genRequest = (req) => {
  req.params = req.params || {};
  req.data = req.data || {};
  req.headers = req.headers || {};
  const jwt_token = ajaxUtil.getJWTToken();
  if (!req.keep_authorization && jwt_token) {
    req.headers.Authorization = jwt_token;
  }
  return req;
};

export default ajaxUtil;
