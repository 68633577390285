import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// import LoginFooter from './LoginFooter';
import FlashError from "../common/FlashError";
import BuildVersion from "../common/BuildVersion";
import * as loginAction from "../../actions/login_actions";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "@material-ui/core/Button";

// const Translate = require('react-i18nify').Translate;
// const Localize = require('react-i18nify').Localize;
import { translate } from 'react-i18nify';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => {
      if (document.getElementById("input-with-icon-textfield").value != "") {
        this.setState({ shrink: true });
      }
    }, 500);
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  render() {
    const shrinkState = this.state.shrink ? {} : { shrink: true };
    return (
      <form onSubmit={this.props.onSubmit}>
        <div className="user_input_container">
          <div className="user_input_wraper">
            <TextField
              id="input-with-icon-textfield"
              style={{ marginBottom: "25px", width: "340px" }}
              variant="outlined"
              label="Email"
              onChange={this.props.onUserInputChange}
              InputLabelProps={shrinkState}
              autoComplete="email"
            />
            <TextField
              id="outlined-adornment-password"
              style={{ marginBottom: "25px", width: "340px" }}
              variant="outlined"
              type={this.state.showPassword ? "text" : "password"}
              label="Password"
              onChange={this.props.onPasswordInputChange}
              autoComplete="password"
              InputLabelProps={shrinkState}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              disabled={this.props.login.inLogin}
              style={{
                backgroundColor: "#3EC880",
                color: "#ffffff",
                width: "340px",
                height: "55px",
                opacity: this.props.login.inLogin ? 0.5 : 1,
              }}
              onClick={this.props.onSubmit}
              autoFocus
            >
              {translate("login_page.login")}
            </Button>

            {/* <LoginFooter
              togglCheckBox={this.props.togglCheckBox}
              check_box_img={this.state.remember_me ? '/images/remember_me_checked.png' : '/images/remember_me.png'}
            /> */}
            <FlashError message={this.props.login.errors} />
          </div>
          <BuildVersion />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(loginAction, dispatch),
  };
};

LoginForm.propTypes = {
  login: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUserInputChange: PropTypes.func.isRequired,
  onPasswordInputChange: PropTypes.func.isRequired,
  togglCheckBox: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
