import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BuildVersion from "../common/BuildVersion";

import * as Bowser from "bowser";

import * as loginActions from "../../actions/login_actions";
import LoginForm from "./LoginForm";
import "./../../css/login/Login.scss";
import cookie from "../../utils/cookie";

const browser = Bowser.getParser(window.navigator.userAgent);
const isValidBrowser = browser.satisfies({
  chrome: ">=56",
  chromium: ">=56",
  opera: ">=43",
  edge: ">=79",
  safari: ">=11",
  android: ">=87",
  yandex: ">=17",
  qq: ">=9.6",
  electron: ">=10",
  // firefox: ">31",
});

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      remember_me: false,
    };
    const loadingDone = () => {
      this.setState({ loading: false });
    };

    if (cookie("jwt_token")) {
      this.state.loading = true;
      this.props.actions.rememberMe(loadingDone);
    }
  }

  onUserInputChange = (e) => {
    this.setState({ email: e.target.value });
  };

  onPasswordInputChange = (e) => {
    this.setState({ password: e.target.value });
  };

  togglCheckBox = (e) => {
    this.setState({ remember_me: !this.state.remember_me });
  };

  onKeyPress = (event) => {
    if (event.charCode == 13) {
      this.onSubmit(event);
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.props.login.inLogin) {
      return;
    }
    this.props.actions.declareInLoginProcess();
    this.props.actions.onSubmit(this.state);
  };

  render() {
    if (!this.state.loading) {
      return (
        <div className="main_wrapper" onKeyPress={this.onKeyPress}>
          <div className="login_container">
            <div className="login_header">
              <div className="header_img" />
            </div>
            {isValidBrowser ? (
              <LoginForm
                onSubmit={this.onSubmit}
                onUserInputChange={this.onUserInputChange}
                onPasswordInputChange={this.onPasswordInputChange}
                togglCheckBox={this.togglCheckBox}
              />
            ) : (
              <div className="nochrome">
                Sorry this application runs on the following browsers only
                <div className="browsers">
                  <a href="https://www.google.com/chrome/browser/desktop/index.html">
                    <div className="chrome">
                      <div className="chromeimg" />
                      <div className="browsers_text">Google Chrome</div>
                    </div>
                  </a>
                  <a href="http://browser.qq.com/">
                    <div className="qq">
                      <div className="qqimg" />
                      <div className="browsers_text">QQ 浏览器</div>
                    </div>
                  </a>
                </div>
                <BuildVersion />
              </div>
            )}
          </div>
        </div>
      );
    }
    return <div />;
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(loginActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
