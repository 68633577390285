export const CSRF_TEST = "/csrf_test";
export const CSRF_TEST_SUCCESS = "CSRF_TEST_SUCCESS";
export const CSRF_TEST_ERROR = "CSRF_TEST_ERROR";
export function csrfTest() {
  return {
    type: "API",
    request: {
      method: "post",
      url: "/app/csrf_test",
      type: "CSRF_TEST",
    },
  };
}
