import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import createLogger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import LogRocket from "logrocket";

// import { USE_REDUX_LOGGER } from '../configs';
import rootReducer from "../reducers";

const middlewares = [thunk];

// if (USE_REDUX_LOGGER) {
//   const logger = createLogger();
//   middlewares.push(logger);
// }

export default function configureStore() {
  return createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
}
