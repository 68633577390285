import * as actionTypes from "../actions/actions_types";
import initialState from "./initialState";
import _ from "lodash";
import Logger from "../helpers/logger";
import LogRocket from "logrocket";
import { history } from "../history";

export default function wizardReducer(state = initialState.wizard, action) {
  const newState = state;
  let selectedJoint = null;
  let selectedMotion = null;
  let secondaryJoint = null;
  let secondaryMotion = null;
  let selectedFingers = [true, true, false, true, true, true];
  let selectedSide = null;
  let is2D;
  switch (action.type) {
    case actionTypes.UPDATE_CURRENT_PAGE:
      return Object.assign({}, state, {
        currentPage: action.currentPage,
        outOfActivity: false,
      });

    case actionTypes.UPDATE_TASK_TYPE:
      return Object.assign({}, state, {
        taskType: action.taskType,
      });

    case actionTypes.BACK_TO_ACTIVITY_SUCCESS:
      return Object.assign({}, state, {
        outOfActivity: false,
        currentPage: action.lastWizardPage,
        inBackToActivity: true,
      });

    case actionTypes.END_BACK_TO_ACTIVITY:
      return Object.assign({}, state, {
        inBackToActivity: false,
      });

    case actionTypes.DECLARE_GAME_STATE:
      return Object.assign({}, state, {
        gameInProgress: action.gameInProgress,
      });

    case actionTypes.DECLARE_GAME_MOVE:
      return Object.assign({}, state, {
        gameMove: state.gameMove + 1,
      });

    case actionTypes.GO_TO_DRS:

      return Object.assign({}, state, {
        activeTask: state.sporadicTask ? null : state.activeTask,
      });

    case actionTypes.SERVICE_STATUS_UPDATE:
      return Object.assign({}, state, {
        serviceStatus: action.serviceStatus,
        serviceShouldUpdate: action.serviceShouldUpdate,
        serviceVersion: action.serviceVersion,
      });

    case actionTypes.EVALUATION_SELECTED_SUCCESS:
      return Object.assign({}, state, {
        wizardType: action.ev_type,
      });

    case actionTypes.THERAPIST_EVALUATION_SUCCESS:
    case actionTypes.GO_TO_DASHBOARD:
    case actionTypes.CLINICIAN_HANG_UP_SUCCESS:
    case actionTypes.ON_REMOTE_HANG_UP_FROM_PATIENT:
    case actionTypes.ON_SESSION_INTERRUPTED:
    case actionTypes.ON_EXIT_WIZARD_SUCCESS:
      if (action.inRemoteSession == null || action.inRemoteSession === false) {
        return Object.assign({}, state, {
          currentPage: null,
          inActivity: false,
          outOfActivity: false,
          activeTask: null,
          sporadicTask: false,
          activeTreatment: null,
          is2D: false,
          taskType: null,
          selectedGame: null,
          selectedDevice: null,
          selectedJoint: null,
          selectedMotion: null,
          currentGameSettings: [],
          secondaryJoint: null,
          secondaryMotion: null,
          selectedFingers: null,
          selectedSide: null,
          wizardType: null,
          gameInProgress: null,
          gameMove: 0,
          error: "",
          task: null,
          selectedVestibularGroup: null,
        });
      }
      return Object.assign({}, state, {
        inActivity: action.inRemoteSession,
        outOfActivity: action.inRemoteSession,
        taskType: null,
      });

    case actionTypes.GO_BACK_FROM_SELECT_DEVICE:
    case actionTypes.GO_TO_GAME_SELECT:
      return Object.assign({}, state, {
        currentPage: "game_select",
        activeTask: null,
        activeTreatment: null,
        selectedGame: null,
      });

    case actionTypes.DEVICE_DISCONNECTED:
      console.error("Device Disconnected: " + state.selectedDevice.name);
      return Object.assign({}, state, {
        currentPage: "select_device",
        selectedDevice: null,
      });

    case actionTypes.START_TREATMENT_SUCCESS:
      return Object.assign({}, state, {
        currentPage: "game_select",
        // patientWizardDetails: action.patientWizardDetails,
        // tasks: action.patientTasks,
        wizardType: "treatment",
        inActivity: true,
        selectedGame: null,
        outOfActivity: false,
        activeTask: action.task,
        sporadicTask: false,
        activeTreatment: action.treatment,
        role: action.role,
      });

    case actionTypes.START_FUNCTIONAL_TREATMENT_SUCCESS:
      return Object.assign({}, state, {
        currentPage: "select_device",
        // patientWizardDetails: action.patientWizardDetails,
        // tasks: action.patientTasks,
        wizardType: "functional",
        inActivity: true,
        selectedGame: null,
        outOfActivity: false,
        activeTask: action.task,
        sporadicTask: false,
        activeTreatment: action.task,
        role: action.role,
      });

    case actionTypes.START_VESTIBULAR_TREATMENT_SUCCESS:
      return Object.assign({}, state, {
        currentPage: "game_select",
        // patientWizardDetails: action.patientWizardDetails,
        // tasks: action.patientTasks,
        wizardType: "vestibular",
        inActivity: true,
        selectedGame: null,
        outOfActivity: false,
        activeTask: action.task,
        sporadicTask: false,
        activeTreatment: action.task,
        role: action.role,
      });

    case actionTypes.START_FUNCTIONAL_TREATMENT_SUCCESS_TASK:
      return Object.assign({}, state, {
        currentPage: "select_device",
        // patientWizardDetails: action.patientWizardDetails,
        // tasks: action.patientTasks,
        wizardType: "functional",
        inActivity: true,
        outOfActivity: false,
        activeTask: action.task,
        sporadicTask: false,
        activeTreatment: action.task,
        role: action.role,
      });

    case actionTypes.START_VESTIBULAR_TREATMENT_SUCCESS_TASK:
      return Object.assign({}, state, {
        currentPage: "select_area",
        // patientWizardDetails: action.patientWizardDetails,
        // tasks: action.patientTasks,
        wizardType: "vestibular",
        inActivity: true,
        outOfActivity: false,
        activeTask: action.task,
        sporadicTask: false,
        activeTreatment: action.task,
        role: action.role,
      });

    case actionTypes.GAME_SELECTED_SUCCESS:
      return Object.assign({}, state, {
        currentPage: "select_device",
        selectedGame: action.selectedGame,
        currentGameSettings: action.gameDefaultSettings,
        wizardType: action.wizardType ?? state.wizardType,
        task: action?.task,
        activeTask: action?.task,
        selectedVestibularGroup: action?.vestibularGroup,
        // selectedJoint: action?.task?.TaskMovements[0]?.BodyPart,
        // selectedMotion: action?.task?.TaskMovements[0]?.BodyPartPosition,
        // selectedSide: action?.task?.TaskMovements[0]?.Side,
        // selectedDevice: action?.task?.TaskMovements[0]?.DeviceType,
      });

    case actionTypes.SELECT_DEVICE_SUCCESS:
      selectedJoint = null;
      selectedMotion = null;
      secondaryJoint = null;
      secondaryMotion = null;
      selectedFingers = null;
      is2D = false;
      selectedSide = null;
      if (state.activeTask) {
        selectedJoint = state.activeTask.TaskMovements[0].BodyPart;
        selectedMotion = state.activeTask.TaskMovements[0].BodyPartPosition;
        selectedSide = state.activeTask.TaskMovements[0].Side;
        if (state.activeTask.TaskMovements[0].SelectedFingers) {
          selectedFingers = state.activeTask.TaskMovements[0].SelectedFingers;
        } else if (action.selectedDevice.name === "HandTutor") {
          // for old tasks with no fingers stored
          if (state.activeTask.TaskMovements.length > 1) {
            selectedFingers = [true, true, true, true, true, true];
          } else if (selectedJoint.name === "Wrist") {
            selectedFingers = [false, false, true, false, false, false];
          } else {
            selectedFingers = [true, true, false, true, true, true];
          }
        }
        if (state.activeTask.TaskMovements.length > 1) {
          is2D = true;
          secondaryJoint = state.activeTask.TaskMovements[1].BodyPart;
          secondaryMotion = state.activeTask.TaskMovements[1].BodyPartPosition;
        }
      } else if (state?.task?.TaskMovements[0]) {
        selectedJoint = state.task.TaskMovements[0].BodyPart;
        selectedMotion = state.task.TaskMovements[0].BodyPartPosition;
        selectedSide = state.task.TaskMovements[0].Side;
        if (state.task.TaskMovements[0].SelectedFingers) {
          selectedFingers = state.task.TaskMovements[0].SelectedFingers;
        } else if (action.selectedDevice.name === "HandTutor") {
          // for old tasks with no fingers stored
          if (state.task.TaskMovements.length > 1) {
            selectedFingers = [true, true, true, true, true, true];
          } else if (selectedJoint.name === "Wrist") {
            selectedFingers = [false, false, true, false, false, false];
          } else {
            selectedFingers = [true, true, false, true, true, true];
          }
        }
        if (state.task.TaskMovements.length > 1) {
          is2D = true;
          secondaryJoint = state.task.TaskMovements[1].BodyPart;
          secondaryMotion = state.task.TaskMovements[1].BodyPartPosition;
        }
      } else {
        if (action.selectedDevice.info !== "WT901BLE58") {
          if (action?.selectedVestibularGroup) {
            selectedJoint = action.selectedDevice.BodyParts.find(
              (x) => x.name === action.selectedVestibularGroup
            );
          } else {
            selectedJoint = action.selectedDevice.BodyParts.sort(
              (a, b) => a.id - b.id
            )[0];
          }

          selectedMotion = selectedJoint?.BodyPartPositions.sort(
            (a, b) => a.id - b.id
          )[0];
          if (action.selectedDevice.name === "HandTutor") {
            if (selectedJoint?.name === "Wrist") {
              selectedFingers = [false, false, true, false, false, false];
            } else {
              selectedFingers = [true, true, false, true, true, true];
            }
          }

          if (action.selectedDevice.name !== "3dTutur") {
            selectedSide =
              action.selectedDevice.type.slice(-1) === "L" ? "L" : "R";
          }
        }
      }
      return Object.assign({}, state, {
        currentPage: "select_area",
        selectedDevice: action.selectedDevice,
        is2D,
        selectedJoint,
        selectedMotion,
        secondaryJoint,
        secondaryMotion,
        selectedFingers,
        selectedSide,
        error: "",
        selectedVestibularGroup: action?.vestibularGroup ?? action?.selectedVestibularGroup,
        eulerAxis: 0,
      });

    case actionTypes.SET_DRS_RANGES:
      is2D = action.is2D;
      selectedJoint = action.selectedJoint;
      selectedMotion = action.selectedMotion;
      secondaryJoint = action.secondaryJoint;
      secondaryMotion = action.secondaryMotion;
      selectedFingers = action.selectedFingers;
      selectedSide = action.selectedSide;

      return Object.assign({}, state, {
        is2D,
        selectedJoint,
        selectedMotion,
        secondaryJoint,
        secondaryMotion,
        selectedFingers,
        selectedSide,
      });

    case actionTypes.CHANGE_2D_SETTING:
      secondaryJoint = null;
      secondaryMotion = null;
      selectedFingers = state.selectedFingers;
      if (action.is2D) {
        if (state.selectedJoint.BodyPartPositions.length > 1) {
          secondaryJoint = state.selectedJoint;
          secondaryMotion = secondaryJoint.BodyPartPositions.filter(
            (m) => m.id != state.selectedMotion.id
          ).sort((a, b) => a.id - b.id)[0];
        } else {
          secondaryJoint = state.selectedDevice.BodyParts.filter(
            (j) => j.id != state.selectedJoint.id
          ).sort((a, b) => a.id - b.id)[0];
          secondaryMotion = secondaryJoint.BodyPartPositions.sort(
            (a, b) => a.id - b.id
          )[0];
        }
      }

      if (state.selectedDevice.name === "HandTutor") {
        if (action.is2D) {
          if (state.selectedJoint.name === "Wrist") {
            selectedFingers = [true, true, true, true, true, true];
          } else {
            selectedFingers[2] = true;
          }
        } else if (state.selectedJoint.name === "Wrist") {
          selectedFingers = [false, false, true, false, false, false];
        } else {
          selectedFingers = [true, true, false, true, true, true];
        }
      }

      return Object.assign({}, state, {
        currentPage: "select_area", // 'set_range',
        is2D: action.is2D,
        secondaryJoint,
        secondaryMotion,
        selectedFingers,
        error: "",
      });

    case actionTypes.SELECT_JOINT:
      selectedFingers = state.selectedFingers;
      secondaryJoint = state.secondaryJoint;
      secondaryMotion = state.secondaryMotion;
      selectedMotion = action.selectedJoint.BodyPartPositions.sort(
        (a, b) => a.id - b.id
      )[0];
      if (state.is2D === true) {
        if (state.selectedDevice.name === "3DTutor") {
          secondaryJoint = action.selectedJoint;
        }
        if (secondaryJoint.id === action.selectedJoint.id) {
          if (action.selectedJoint.BodyPartPositions.length > 1) {
            if (state.secondaryMotion.id === selectedMotion.id) {
              secondaryMotion = secondaryJoint.BodyPartPositions.filter(
                (m) => m.id != state.selectedMotion.id
              ).sort((a, b) => a.id - b.id)[0];
            }
          } else {
            secondaryJoint = state.selectedDevice.BodyParts.filter(
              (j) => j.id != action.selectedJoint.id
            ).sort((a, b) => a.id - b.id)[0];
            secondaryMotion = state.secondaryJoint.BodyPartPositions.sort(
              (a, b) => a.id - b.id
            )[0];
          }
        }
      } else if (state.selectedDevice.name === "HandTutor") {
        if (action.selectedJoint.name === "Wrist") {
          selectedFingers = [false, false, true, false, false, false];
        } else {
          selectedFingers = [true, true, false, true, true, true];
        }
      }
      return Object.assign({}, state, {
        currentPage: "select_area", // 'determine_range', //TODO:should check all this mechanism, should search "determine_range" through the application
        selectedJoint: action.selectedJoint,
        selectedMotion,
        selectedFingers,
        secondaryJoint,
        secondaryMotion,
        error: "",
      });

    case actionTypes.SELECT_MOTION:
      secondaryMotion = state.secondaryMotion;
      selectedFingers = state.selectedFingers;
      if (
        state.is2D === true &&
        state.secondaryMotion.id === action.selectedMotion.id &&
        state.secondaryJoint.BodyPartPositions.length != 1
      ) {
        secondaryMotion = state.secondaryJoint.BodyPartPositions.filter(
          (m) => m.id != action.selectedMotion.id
        );
        secondaryMotion = secondaryMotion[0];
      } else if (
        state.wizardType === "functional" &&
        state.selectedDevice.name === "HandTutor" &&
        state.selectedJoint.name === "Fingers"
      ) {
        switch (action.selectedMotion.name) {
          case "Fingers flexion against resistance":
            //////////////////[pink, ring, wrist, thum, indx, middl];
            selectedFingers = [true, true, false, false, true, true];
            break;
          case "Opposition index thumb":
            selectedFingers = [false, false, false, true, true, false];
            break;
          case "Opposition middle thumb":
            selectedFingers = [false, false, false, true, false, true];
            break;
          case "Opposiiton ring thumb":
            selectedFingers = [false, true, false, true, false, false];
            break;
          case "Opposition pinky thumb":
            selectedFingers = [true, false, false, true, false, false];
            break;
          case "Flexion index":
            selectedFingers = [false, false, false, false, true, false];
            break;
          case "Flexion middle":
            selectedFingers = [false, false, false, false, false, true];
            break;
          case "Flexion ring":
            selectedFingers = [false, true, false, false, false, false];
            break;
          case "Flexion pinky":
            selectedFingers = [true, false, false, false, false, false];
            break;
          case "Flexion thumb":
            selectedFingers = [false, false, false, true, false, false];
            break;
          case "Flexion full fist":
            selectedFingers = [true, true, false, true, true, true];
            break;
          case "Index flexion against resistance":
            selectedFingers = [false, false, false, false, true, false];
            break;
          case "Middle flexion against resistance":
            selectedFingers = [false, false, false, false, false, true];
            break;
          case "Ring flexion against resistance":
            selectedFingers = [false, true, false, false, false, false];
            break;
          case "Pinky flexion against resistance":
            selectedFingers = [true, false, false, false, false, false];
            break;
          case "Ball grip":
            selectedFingers = [true, true, false, true, true, true];
            break;
          default:
            selectedFingers = [true, true, false, true, true, true];
        }
      }
      let currentGameSettings = _.cloneDeep(state.currentGameSettings);
      let eulerAxis = 0;
      if (
        action.selectedMotion.id === 110 ||
        action.selectedMotion.id === 111 ||
        action.selectedMotion.id === 116 ||
        action.selectedMotion.id === 117 ||
        action.selectedMotion.id === 118
      ) {
        currentGameSettings.moreSettings["Axis"] = 1;
        eulerAxis = 1;
      } else {
        if (currentGameSettings !== undefined && !!currentGameSettings.length) {
          currentGameSettings.moreSettings["Axis"] = 0;
        }
        eulerAxis = 0;
        if (currentGameSettings && currentGameSettings.length){
          currentGameSettings.moreSettings["Axis"] = 0;
        }
      }

      return Object.assign({}, state, {
        selectedMotion: action.selectedMotion,
        secondaryMotion,
        selectedFingers,
        error: "",
        currentGameSettings,
        eulerAxis,
      });

    case actionTypes.SELECT_SIDE:
      return Object.assign({}, state, {
        selectedSide:
          action.selectedSide ?? (state.selectedSide === "R" ? "L" : "R"),
        error: "",
      });

    case actionTypes.SELECT_SECONDARY_JOINT:
      return Object.assign({}, state, {
        secondaryJoint: action.secondaryJoint,
        secondaryMotion: action.secondaryJoint.BodyPartPositions.sort(
          (a, b) => a.id - b.id
        )[0],
        error: "",
      });

    case actionTypes.SELECT_SECONDARY_MOTION:
      return Object.assign({}, state, {
        currentPage: "select_area", // 'set_range',
        secondaryMotion: action.secondaryMotion,
        error: "",
      });

    case actionTypes.CHANGED_SELECTED_FINGERS_SUCCESS:
      return Object.assign({}, state, {
        currentPage: "select_area", // 'set_range',
        selectedFingers: action.selected_fingers,
        error: "",
      });

    case actionTypes.CONNECTED_DEVICES_SUCCESS:
      const calibratedDevices = [];
      const multiSensorDevicesCalibration = [];
      _.forEach(action.connectedDevices, (d) => {
        const serial = d.serialNumber;
        calibratedDevices[serial] =
          (state.calibratedDevices ? state.calibratedDevices[serial] : false) ||
          false;
        if (
          state.multiSensorDevicesCalibration &&
          state.multiSensorDevicesCalibration[serial]
        ) {
          multiSensorDevicesCalibration[serial] =
            state.multiSensorDevicesCalibration[serial];
        }
      });
      if (!action.connectedDevices.find(x => x['serialNumber'] === "A000000000")){
        action.connectedDevices.push({"serialNumber":"A000000000","DeviceType":{"id":16,"name":"NoDevice","model":"NoDevice","type":""}});
      }
      return Object.assign({}, state, {
        connectedDevices: action.connectedDevices,
        calibratedDevices,
        multiSensorDevicesCalibration,
      });

    case actionTypes.AVAILABLE_BLUETOOTH_DEVICES:
      return Object.assign({}, state, {
        bluetoothDevices: action.foundDevices,
        connectionStatus: action.connectionStatus,
      });

    case actionTypes.BLUETOOTH_RADIO_EXISTENS_CHANGED:
      return Object.assign({}, state, {
        bluetoothRadioNotExists: !action.exists,
      });

    case actionTypes.KEEP_SEARCHING_FOR_BLUETOOTH_DEVICES:
      return Object.assign({}, state, {
        keepSearchingForBluetoothDevices: action.shouldSearch,
      });

    case actionTypes.RESET_DEVICES:
      return Object.assign({}, state, {
        connectedDevices: [],
        calibratedDevices: [],
      });

    case actionTypes.CALIBRATED_DEVCIES_CHANGED:
      return Object.assign({}, state, {
        calibratedDevices:
          action.calibratedDevices == null
            ? state.calibratedDevices
            : action.calibratedDevices,
        multiSensorDevicesCalibration:
          action.multiSensorDevicesCalibration == null
            ? state.multiSensorDevicesCalibration
            : action.multiSensorDevicesCalibration,
      });

    case actionTypes.APPLY_GAME_SETTINGS:
      return Object.assign({}, state, {
        currentGameSettings: action.settings,
      });

    case actionTypes.APPLY_TASK:
      return Object.assign({}, state, {
        activeTask: action.task,
        sporadicTask: true,
      });

    case actionTypes.APPLY_UNANIMOUS_TASK:
      return Object.assign({}, state, {
        activeTask: null,
        sporadicTask: true,
      });

    case actionTypes.RELOAD_TASKS_SUCCESS:
    case actionTypes.CHANGE_TASKS_SUCCESS:
      return Object.assign({}, state, {
        tasks: action.tasks,
      });

    case actionTypes.SAVE_TASK_SUCCESS:
      return Object.assign({}, state, {
        activeTask: action.savedTask,
        sporadicTask: true,
      });

    case actionTypes.ON_SET_DRS_MAX_MIN_RANGES:
      if (action.primary) {
        return Object.assign({}, state, {
          minDrs: action.minDrs,
          maxDrs: action.maxDrs,
        });
      }

      return Object.assign({}, state, {
        minDrs2d: action.minDrs,
        maxDrs2d: action.maxDrs,
      });

    case actionTypes.ON_SET_DRS_MAX_MIN_RANGES_FINGERS:
      return Object.assign({}, state, {
        selectedFingers: action.selectedFingers,
        minDrsLittle: action.minDrsLittle,
        maxDrsLittle: action.maxDrsLittle,
        minDrsRing: action.minDrsRing,
        maxDrsRing: action.maxDrsRing,
        minDrsMiddle: action.minDrsMiddle,
        maxDrsMiddle: action.maxDrsMiddle,
        minDrsIndex: action.minDrsIndex,
        maxDrsIndex: action.maxDrsIndex,
        minDrsThumb: action.minDrsThumb,
        maxDrsThumb: action.maxDrsThumb,
      });

    case actionTypes.ON_SET_TREATMENT_RANGES:
      if (action.primary) {
        return Object.assign({}, state, {
          minGame: action.minGame,
          maxGame: action.maxGame,
          gameMove: state.gameMove + 1,
        });
      }

      return Object.assign({}, state, {
        minGame2d: action.minGame,
        maxGame2d: action.maxGame,
      });

    // error handling from wizard flow :
    case actionTypes.START_TREATMENT_ERROR:
    case actionTypes.GAME_SELECTED_ERROR:
    case actionTypes.SELECT_DEVICE_ERROR:
    case actionTypes.BACK_TO_ACTIVITY_ERROR:
    case actionTypes.RELOAD_TASKS_ERROR:
    case actionTypes.SAVE_TASK_ERROR:
    case actionTypes.CHANGE_TASKS_ERROR:
    case actionTypes.ON_EXIT_WIZARD_ERROR:
      console.log(action.error);
      Logger.logFatal(
        "Global error in wizard reducer",
        action.error.message,
        action.error.stack,
        "wizard state",
        state
      );
      return Object.assign({}, state, { error: action.error });

    default:
      return state;
  }
}
