import PropTypes from "prop-types";

const FlashError = ({ message }) => {
  return (
    <div>
      {message === "" ? "" : <div className="error-msg">{message}</div>}
    </div>
  );
};

FlashError.propTypes = {
  message: PropTypes.string,
};

export default FlashError;
