import * as actionTypes from "../actions/actions_types";
import initialState from "./initialState";
import update from "immutability-helper";
import _ from "lodash";

export default function evaluationReducer(
  state = initialState.evaluation,
  action
) {
  let newState = state;
  switch (action.type) {
    case actionTypes.UPDATE_CURRENT_PAGE:
      return Object.assign({}, state, { currentPage: action.currentPage });

    case actionTypes.THERAPIST_EVALUATION_SUCCESS:
    case actionTypes.GO_BACK_FROM_SELECT_DEVICE:
      return Object.assign({}, state, {
        inActivity: true,
        evaluationType: null,
        evaluationJoint: null,
        selectedDevice: null,
        outOfActivity: false,
        currentPage: "evaluation_select",
      });

    case actionTypes.START_TREATMENT_SUCCESS:
      return Object.assign({}, state, {
        inActivity: false,
        evaluationType: null,
        evaluationJoint: null,
        selectedDevice: null,
        outOfActivity: false,
      });

    case actionTypes.EVALUATION_SELECTED_SUCCESS:
      return Object.assign({}, state, {
        evaluationType: action.ev_type,
        evaluationJoint: action.ev_joint,
        selectedDevice: null,
        outOfActivity: false,
        currentPage: "evaluation_select_device",
      });

    case actionTypes.EVALUATION_DEVICE_SELECTED_SUCCESS:
      return Object.assign({}, state, {
        selectedDevice: action.selectedDevice,
        outOfActivity: false,
        currentPage: "start_evaluation",
      });

    case actionTypes.EVALUATION_BACK_TO_ACTIVITY_SUCCESS:
      return Object.assign({}, state, {
        outOfActivity: false,
        currentPage: action.lastActivityPage,
      });

    case actionTypes.EVALUATION_GO_TO_DASHBOARD:
    case actionTypes.CLINICIAN_HANG_UP_SUCCESS:
      if (action.inRemoteSession == null || action.inRemoteSession === false) {
        return Object.assign({}, state, {
          evaluationType: action.ev_type,
          evaluationJoint: action.ev_joint,
          selectedDevice: null,
          inActivity: false,
          outOfActivity: false,
        });
      }
      return Object.assign({}, state, {
        inActivity: action.inRemoteSession,
        outOfActivity: action.inRemoteSession,
      });

    case actionTypes.EVALUATION_MOTION_ANALYSIS_STATE:
      return Object.assign({}, state, {
        evaluationMotionAnalysisState: action.state,
      });

    case actionTypes.CONNECTED_DEVICES_SUCCESS:
      if (state.selectedDevice) {
        newState = update(state, {
          selectedDevice: {
            connected: {
              $set: _.includes(
                action.connectedDevices.map((cd) => cd.serialNumber),
                state.selectedDevice.serialNumber
              ),
            },
          },
        });
        return Object.assign({}, state, newState);
      }
      return Object.assign({}, state, {});

    // error handling from evaluation flow :
    case actionTypes.THERAPIST_EVALUATION_ERROR:
    case actionTypes.EVALUATION_SELECTED_ERROR:
    case actionTypes.EVALUATION_BACK_TO_ACTIVITY_ERROR:
      console.log(action.error);
      return Object.assign({}, state, { error: action.error });

    default:
      return state;
  }
}
