export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const DECLARE_IN_LOGIN_PROCESS = "DECLARE_IN_LOGIN_PROCESS";
export const LOGOUT = "LOGOUT";
export const GET_USER_DETAILS_ERROR = "GET_USER_DETAILS_ERROR";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";

export const SERVER_CONNECTION_STATUS = "SERVER_CONNECTION_STATUS";

export const START_FUNCTIONAL_TREATMENT_SUCCESS = "START_FUNCTIONAL_TREATMENT_SUCCESS";
export const START_FUNCTIONAL_TREATMENT_SUCCESS_TASK = "START_FUNCTIONAL_TREATMENT_SUCCESS_TASK";
export const START_VESTIBULAR_TREATMENT_SUCCESS = "START_VESTIBULAR_TREATMENT_SUCCESS";
export const START_VESTIBULAR_TREATMENT_SUCCESS_TASK = "START_VESTIBULAR_TREATMENT_SUCCESS_TASK";
export const START_TREATMENT_SUCCESS = "START_TREATMENT_SUCCESS";
export const START_TREATMENT_ERROR = "START_TREATMENT_ERROR";
export const NOTIFICATION_POLLING_SUCCESS = "NOTIFICATION_POLLING_SUCCESS";
export const NOTIFICATION_POLLING_ERROR = "NOTIFICATION_POLLING_ERROR";

export const LOG_EVENT_DEBUG = "LOG_EVENT_DEBUG";
export const LOG_EVENT_INFO = "LOG_EVENT_INFO";
export const LOG_EVENT_WARN = "LOG_EVENT_WARN";
export const LOG_EVENT_ERROR = "LOG_EVENT_ERROR";
export const LOG_EVENT_FATAL = "LOG_EVENT_FATAL";
export const LOGS_ACKNOWLEDGED = "LOGS_ACKNOWLEDGED";

//* **************************patient action types*************************

export const LOGIN_SUCCESS_PATIENT = "LOGIN_SUCCESS_PATIENT";
export const LOGIN_SUCCESS_SITEADMIN = "LOGIN_SUCCESS_SITEADMIN";
export const LOGIN_SUCCESS_ADMIN = "LOGIN_SUCCESS_ADMIN";
export const LOGIN_SUCCESS_SUPERADMIN = "LOGIN_SUCCESS_SUPERADMIN";

export const GET_PATIENT_UPCOMING_APPOINTMENTS_SUCCESS =
  "GET_PATIENT_UPCOMING_APPOINTMENTS_SUCCESS";
export const GET_PATIENT_UPCOMING_APPOINTMENTS_ERROR =
  "GET_PATIENT_UPCOMING_APPOINTMENTS_ERROR";
export const GET_PATIENT_TREATMENT_PLAN_SUCCESS =
  "GET_PATIENT_TREATMENT_PLAN_SUCCESS";
export const GET_PATIENT_TREATMENT_PLAN_ERROR =
  "GET_PATIENT_TREATMENT_PLAN_ERROR";

export const PATIENT_GET_COMPLETED_TASKS_SUCCESS =
  "PATIENT_GET_COMPLETED_TASKS_SUCCESS";
export const PATIENT_GET_COMPLETED_TASKS_ERROR =
  "PATIENT_GET_COMPLETED_TASKS_ERROR";

export const GET_GAMES_SETTINGS_SUCCESS = "GET_GAMES_SETTINGS_SUCCESS";
export const GET_GAMES_SETTINGS_ERROR = "GET_GAMES_SETTINGS_ERROR";

//* **************************wizard action types*************************
export const GO_BACK_FROM_SELECT_DEVICE = "GO_BACK_FROM_SELECT_DEVICE";

export const RESET_WIZARD_SETTINGS = "RESET_WIZARD_SETTINGS";
export const SERVICE_STATUS_UPDATE = "SERVICE_STATUS_UPDATE";

export const GO_TO_DASHBOARD = "GO_TO_DASHBOARD";
export const GO_TO_DRS = "GO_TO_DRS";
export const GO_TO_GAME_SELECT = "GO_TO_GAME_SELECT";

export const UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE";
export const UPDATE_TASK_TYPE = "UPDATE_TASK_TYPE";

export const GAME_SELECTED_SUCCESS = "GAME_SELECTED_SUCCESS";
export const GAME_SELECTED_ERROR = "GAME_SELECTED_ERROR";

export const SELECT_DEVICE_SUCCESS = "SELECT_DEVICE_SUCCESS";
export const SELECT_DEVICE_ERROR = "SELECT_DEVICE_ERROR";
export const SET_DRS_RANGES = "SET_DRS_RANGES";
export const SELECT_SIDE = "SELECT_SIDE";
export const SELECT_JOINT = "SELECT_JOINT";
export const SELECT_MOTION = "SELECT_MOTION";
export const SELECT_SECONDARY_JOINT = "SELECT_SECONDARY_JOINT";
export const SELECT_SECONDARY_MOTION = "SELECT_SECONDARY_MOTION";
export const CHANGE_2D_SETTING = "CHANGE_2D_SETTING";
export const CHANGED_SELECTED_FINGERS_SUCCESS =
  "CHANGED_SELECTED_FINGERS_SUCCESS";

export const CONNECTED_DEVICES_SUCCESS = "CONNECTED_DEVICES_SUCCESS";
export const RESET_DEVICES = "RESET_DEVICES";
export const CALIBRATED_DEVCIES_CHANGED = "CALIBRATED_DEVCIES_CHANGED";

export const BACK_TO_ACTIVITY_SUCCESS = "BACK_TO_ACTIVITY_SUCCESS";
export const BACK_TO_ACTIVITY_ERROR = "BACK_TO_ACTIVITY_ERROR";
export const END_BACK_TO_ACTIVITY = "END_BACK_TO_ACTIVITY";

export const DECLARE_GAME_STATE = "DECLARE_GAME_STATE";
export const DECLARE_GAME_MOVE = "DECLARE_GAME_MOVE";

export const ON_SET_DRS_MAX_MIN_RANGES = "ON_SET_DRS_MAX_MIN_RANGES";
export const ON_SET_DRS_MAX_MIN_RANGES_FINGERS =
  "ON_SET_DRS_MAX_MIN_RANGES_FINGERS";
export const ON_SET_TREATMENT_RANGES = "ON_SET_TREATMENT_RANGES";

export const ON_PATIENT_EXIT_GAME_SUCCESS = "ON_PATIENT_EXIT_GAME_SUCCESS";
export const ON_THERPAIST_EXIT_GAME_SUCCESS = "ON_THERPAIST_EXIT_GAME_SUCCESS";
export const ON_EXIT_GAME_ERROR = "ON_EXIT_GAME_ERROR";

export const ON_EXIT_WIZARD_SUCCESS = "ON_EXIT_WIZARD_SUCCESS";
export const ON_EXIT_WIZARD_ERROR = "ON_EXIT_WIZARD_ERROR";

export const DEVICE_DISCONNECTED = "DEVICE_DISCONNECTED";

export const APPLY_GAME_SETTINGS = "APPLY_GAME_SETTINGS";
export const APPLY_TASK = "APPLY_TASK";
export const APPLY_UNANIMOUS_TASK = "APPLY_UNANIMOUS_TASK";
export const SAVE_TASK_SUCCESS = "SAVE_TASK_SUCCESS";
export const SAVE_TASK_ERROR = "SAVE_TASK_ERROR";
export const RELOAD_TASKS_SUCCESS = "RELOAD_TASKS_SUCCESS";
export const RELOAD_TASKS_ERROR = "RELOAD_TASKS_ERROR";
export const CHANGE_TASKS_SUCCESS = "CHANGE_TASKS_SUCCESS";
export const CHANGE_TASKS_ERROR = "CHANGE_TASKS_ERROR";

//* *************************therapist action types**************************
export const LOGIN_SUCCESS_THERAPIST = "LOGIN_SUCCESS_THERAPIST";
export const GET_USER_DETAILS_SUCCESS_THERAPIST =
  "GET_USER_DETAILS_SUCCESS_THERAPIST";

export const GET_PATIENTS_INFO_SUCCESS = "GET_PATIENTS_INFO_SUCCESS";
export const GET_PATIENTS_INFO_ERROR = "GET_PATIENTS_INFO_ERROR";

export const PATIENT_SELECTED_SUCCESS = "PATIENT_SELECTED_SUCCESS";
export const PATIENT_SELECTED_ERROR = "PATIENT_SELECTED_ERROR";
export const PATIENT_DESELECT_ERROR = "PATIENT_DESELECT_ERROR";
export const PATIENT_DESELECT = "PATIENT_DESELECT";

export const POLL_FOR_PATIENT_LOGGED_IN_STATUS_SUCCESS =
  "POLL_FOR_PATIENT_LOGGED_IN_STATUS_SUCCESS";
export const POLL_FOR_PATIENT_LOGGED_IN_STATUS_ERROR =
  "POLL_FOR_PATIENT_LOGGED_IN_STATUS_ERROR";
export const SET_PATIENT_LOGGED_IN_STATUS = "SET_PATIENT_LOGGED_IN_STATUS";

export const GO_BACK_THERAPIST_DASHBOARD = "GO_BACK_THERAPIST_DASHBOARD";

export const GET_ALL_PATIENTS_TREATMENTS_SUCCESS =
  "GET_ALL_PATIENTS_TREATMENTS_SUCCESS";
export const GET_ALL_PATIENTS_TREATMENTS_ERROR =
  "GET_ALL_PATIENTS_TREATMENTS_ERROR";

export const GET_ALL_PATIENTS_SUCCESS = "GET_ALL_PATIENTS_SUCCESS";
export const GET_ALL_PATIENTS_ERROR = "GET_ALL_PATIENTS_ERROR";

export const GET_CLINICIAN_UPCOMING_APPOINTMENTS_SUCCESS =
  "GET_CLINICIAN_UPCOMING_APPOINTMENTS_SUCCESS";
export const GET_CLINICIAN_UPCOMING_APPOINTMENTS_ERROR =
  "GET_CLINICIAN_UPCOMING_APPOINTMENTS_ERROR";
export const CREATE_NEW_APPOINTMENT_SUCCESS = "CREATE_NEW_APPOINTMENT_SUCCESS";
export const CREATE_NEW_APPOINTMENT_ERROR = "CREATE_NEW_APPOINTMENT_ERROR";
export const DELETE_APPOINTMENT_SUCCESS = "DELETE_APPOINTMENT_SUCCESS";
export const DELETE_APPOINTMENT_ERROR = "DELETE_APPOINTMENT_ERROR";

export const GUIDED_WIZARD_SUCCESS = "GUIDED_WIZARD_SUCCESS";
export const GUIDED_WIZARD_ERROR = "GUIDED_WIZARD_ERROR";

export const THERAPIST_REPORT_SUCCESS = "THERAPIST_REPORT_SUCCESS";
export const THERAPIST_REPORT_ERROR = "THERAPIST_REPORT_ERROR";

export const THERAPIST_TREATMENT_ERROR = "THERAPIST_TREATMENT_ERROR";

export const THERAPIST_FINANCE_SUCCESS = "THERAPIST_FINANCE_SUCCESS";
export const THERAPIST_FINANCE_ERROR = "THERAPIST_FINANCE_ERROR";

export const THERAPIST_EVAL_MOTION_DATA_SUCCESS =
  "THERAPIST_EVAL_MOTION_DATA_SUCCESS";
export const THERAPIST_EVAL_MOTION_DATA_ERROR =
  "THERAPIST_EVAL_MOTION_DATA_ERROR";

export const THERAPIST_COMPLITED_TASKS_NOTES_DATA_SUCCESS =
  "THERAPIST_COMPLITED_TASKS_NOTES_DATA_SUCCESS";
export const THERAPIST_COMPLITED_TASKS_NOTES_DATA_ERROR =
  "THERAPIST_COMPLITED_TASKS_NOTES_DATA_ERROR";

export const THERAPIST_EVAL_ANALYSIS_DATA_SUCCESS =
  "THERAPIST_EVAL_ANALYSIS_DATA_SUCCESS";
export const THERAPIST_EVAL_ANALYSIS_DATA_ERROR =
  "THERAPIST_EVAL_ANALYSIS_DATA_ERROR";

export const THERAPIST_GET_COMPLETED_TASKS_SUCCESS =
  "THERAPIST_GET_COMPLETED_TASKS_SUCCESS";
export const THERAPIST_GET_COMPLETED_TASKS_ERROR =
  "THERAPIST_GET_COMPLETED_TASKS_ERROR";

//* *************************evaluation action types**************************

export const THERAPIST_EVALUATION_SUCCESS = "THERAPIST_EVALUATION_SUCCESS";
export const THERAPIST_EVALUATION_ERROR = "THERAPIST_EVALUATION_ERROR";

export const EVALUATION_SELECTED_SUCCESS = "EVALUATION_SELECTED_SUCCESS";
export const EVALUATION_SELECTED_ERROR = "EVALUATION_SELECTED_ERROR";

export const EVALUATION_DEVICE_SELECTED_SUCCESS =
  "EVALUATION_DEVICE_SELECTED_SUCCESS";
export const EVALUATION_DEVICE_SELECTED_ERROR =
  "EVALUATION_DEVICE_SELECTED_ERROR";

export const EVALUATION_MOTION_ANALYSIS_STATE =
  "EVALUATION_MOTION_ANALYSIS_STATE";

export const THERAPIST_GET_EVALUATIONS_LIST_SUCCESS =
  "THERAPIST_GET_EVALUATIONS_LIST_SUCCESS";
export const THERAPIST_GET_EVALUATIONS_LIST_ERROR =
  "THERAPIST_GET_EVALUATIONS_LIST_ERROR";

export const EVALUATION_BACK_TO_ACTIVITY_SUCCESS =
  "EVALUATION_BACK_TO_ACTIVITY_SUCCESS";
export const EVALUATION_BACK_TO_ACTIVITY_ERROR =
  "EVALUATION_BACK_TO_ACTIVITY_ERROR";

export const EVALUATION_GO_TO_DASHBOARD = "EVALUATION_GO_TO_DASHBOARD";

//* ****************** treatment plan modal types ****************************
export const TREATMENT_PLAN_INPUT_CHANGE_MODAL =
  "TREATMENT_PLAN_INPUT_CHANGE_MODAL";
export const TREATMENT_PLAN_ADD_SESSION_IN_MODAL =
  "TREATMENT_PLAN_ADD_SESSION_IN_MODAL";
export const TREATMENT_PLAN_SUBMIT_SUCCESS = "TREATMENT_PLAN_SUBMIT_SUCCESS";
export const TREATMENT_PLAN_SUBMIT_ERROR = "TREATMENT_PLAN_SUBMIT_ERROR";
export const RESET_THREE_TREATMENT_PLAN = "RESET_THREE_TREATMENT_PLAN";

//* ****************** session types ***************************
export const ON_REMOTE_HANG_UP_FROM_PATIENT = "ON_REMOTE_HANG_UP_FROM_PATIENT";
export const CLINICIAN_HANG_UP_SUCCESS = "CLINICIAN_HANG_UP_SUCCESS";
export const CLINICIAN_HANG_UP_ERROR = "CLINICIAN_HANG_UP_ERROR";

export const PATIENT_ON_CLINICIAN_CALLING = "PATIENT_ON_CLINICIAN_CALLING";
export const PATIENT_ON_START_REMOTE_SESSION = "ON_START_REMOTE_SESSION";
export const PATIENT_ANSWER_CALL_SUCCESS = "PATIENT_ANSWER_CALL_SUCCESS";
export const PATIENT_ANSWER_CALL_ERROR = "PATIENT_ANSWER_CALL_ERROR";
export const PATIENT_ANSWER_CALL_SESSION_CREATED =
  "PATIENT_ANSWER_CALL_SESSION_CREATED";
export const PATIENT_HANG_UP_SUCCESS = "PATIENT_HANG_UP_SUCCESS";
export const PATIENT_HANG_UP_ERROR = "PATIENT_HANG_UP_ERROR";
export const PATIENT_END_SESSION_ERROR = "PATIENT_END_SESSION_ERROR";
export const PATIENT_END_SESSION_SUCCESS = "PATIENT_END_SESSION_SUCCESS";
export const PATIENT_START_SESSION_ERROR = "PATIENT_START_SESSION_ERROR";
export const PATIENT_START_SESSION_SUCCESS = "PATIENT_START_SESSION_SUCCESS";
export const ON_REMOTE_HANG_UP_FROM_CLINICIAN =
  "ON_REMOTE_HANG_UP_FROM_CLINICIAN";

export const ON_READY_TO_PLAY_VIDEO = "ON_READY_TO_PLAY_VIDEO";
export const CLINICIAN_CALL_PATIENT_SUCCESS = "CLINICIAN_CALL_PATIENT_SUCCESS";
export const CLINICIAN_CALL_PATIENT_ERROR = "CLINICIAN_CALL_PATIENT_ERROR";
export const CLINICIAN_CALL_PATIENT_SESSION_CREATED =
  "CLINICIAN_CALL_PATIENT_SESSION_CREATED";
export const CLINICIAN_ON_START_REMOTE_SESSION = "ON_START_REMOTE_SESSION";
export const ON_SESSION_INTERRUPTED = "ON_SESSION_INTERRUPTED";

export const AVAILABLE_BLUETOOTH_DEVICES = "AVAILABLE_BLUETOOTH_DEVICES";
export const BLUETOOTH_RADIO_EXISTENS_CHANGED =
  "BLUETOOTH_RADIO_EXISTENS_CHANGED";
export const KEEP_SEARCHING_FOR_BLUETOOTH_DEVICES =
  "KEEP_SEARCHING_FOR_BLUETOOTH_DEVICES";

//* *************************superadmin action types**************************
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_ERROR = "GET_INVOICES_ERROR";

export const SUPERADMIN_GET_TRANSLATION_DATA_SUCCESS =
  "SUPERADMIN_GET_TRANSLATION_DATA_SUCCESS";
export const SUPERADMIN_GET_TRANSLATION_DATA_ERROR =
  "SUPERADMIN_GET_TRANSLATION_DATA_ERROR";

export const POLL_FOR_USERS_LOGGED_IN_STATUS_SUCCESS =
  "POLL_FOR_USERS_LOGGED_IN_STATUS_SUCCESS";
export const SET_LOGGED_IN_USERS = "SET_LOGGED_IN_USERS";

export const GET_USER_SESSION_DETAILS = "GET_USER_SESSION_DETAILS";

export const SAVE_TRANSALTION_UPDATED_DATA_SUCCESS =
  "SAVE_TRANSALTION_UPDATED_DATA_SUCCESS";
export const SAVE_TRANSALTION_UPDATED_DATA_ERROR =
  "SAVE_TRANSALTION_UPDATED_DATA_ERROR";

//* *************************users action types**************************
export const GET_SUB_USERS_SUCCESS = "GET_SUB_USERS_SUCCESS";
export const GET_SUB_USERS_ERROR = "GET_SUB_USERS_ERROR";
export const GET_SUB_USER_DETAILS_SUCCESS = "GET_SUB_USER_DETAILS_SUCCESS";
export const GET_SUB_USER_DETAILS_ERROR = "GET_SUB_USER_DETAILS_ERROR";
export const INVALID_SUB_USER_TO_EDIT = "INVALID_SUB_USER_TO_EDIT";
export const CHANGE_USER_SUCCESS = "CHANGE_USER_SUCCESS";
export const CHANGE_USER_ERROR = "CHANGE_USER_ERROR";
export const UPDATE_SELF_SUCCESS = "UPDATE_SELF_SUCCESS";
export const UPDATE_SELF_ERROR = "UPDATE_SELF_ERROR";
export const USER_LANGUAGE = "USER_LANGUAGE";

//* *************************app action types**************************
export const SHOW_SERVICE_DOWNLOAD_POPUP = "SHOW_SERVICE_DOWNLOAD_POPUP";
export const SIDE_BAR = "SIDE_BAR";
