import * as actionTypes from "../actions/actions_types";
import initialState from "./initialState";
import update from "immutability-helper";

export default function remoteReducer(state = initialState.remote, action) {
  let newState;
  switch (action.type) {
    case actionTypes.CLINICIAN_CALL_PATIENT_SUCCESS:
      newState = update(state, {
        session: { $set: { sessionData: action.sessionData } },
      });
      newState = update(newState, {
        session: { sessionId: { $set: action.sessionData.sessionId } },
      });
      newState = update(newState, {
        callStatus: { $set: "DialingToPatient" },
      });
      newState = update(newState, {
        callHangUp: { $set: false },
      });
      return Object.assign({}, state, newState);

    case actionTypes.CLINICIAN_CALL_PATIENT_SESSION_CREATED:
      return Object.assign({}, state, {
        callStatus: "sessionCreated",
        callHangUp: false,
      });

    case actionTypes.CLINICIAN_ON_START_REMOTE_SESSION:
      return Object.assign({}, state, {
        callStatus: "RemoteSessionOn",
        callHangUp: false,
      });

    case actionTypes.PATIENT_ON_CLINICIAN_CALLING:
      newState = update(state, {
        session: { $set: { therapist: null } },
      });
      newState = update(newState, {
        session: { therapist: { $set: action.therapist_data } },
      });
      newState = update(newState, {
        session: { sessionId: { $set: action.sessionId } },
      });
      newState = update(newState, {
        callStatus: { $set: "ClinicianDialing" },
      });
      newState = update(newState, {
        callHangUp: { $set: false },
      });
      return Object.assign({}, state, newState);

    case actionTypes.PATIENT_ANSWER_CALL_SUCCESS:
      newState = update(state, {
        callStatus: { $set: "PatientAnswered" },
        session: { $set: { sessionData: null } },
      });
      newState = update(state, {
        session: { sessionData: { $set: action.sessionData } },
      });
      return Object.assign({}, state, newState);

    case actionTypes.PATIENT_ANSWER_CALL_SESSION_CREATED:
      return Object.assign({}, state, { callStatus: "sessionCreated" });

    case actionTypes.PATIENT_ON_START_REMOTE_SESSION:
      return Object.assign({}, state, { callStatus: "RemoteSessionOn" });

    case actionTypes.ON_READY_TO_PLAY_VIDEO:
      return Object.assign({}, state, { readyToPlayVideo: action.ready });

    case actionTypes.PATIENT_HANG_UP_SUCCESS:
    case actionTypes.ON_REMOTE_HANG_UP_FROM_CLINICIAN:
    case actionTypes.ON_REMOTE_HANG_UP_FROM_PATIENT:
    case actionTypes.CLINICIAN_HANG_UP_SUCCESS:
    case actionTypes.ON_SESSION_INTERRUPTED:
      return Object.assign({}, state, {
        callStatus: null,
        session: null,
        callHangUp: true,
      });

    // error handling :
    case actionTypes.CLINICIAN_CALL_PATIENT_ERROR:
    case actionTypes.CLINICIAN_HANG_UP_ERROR:
    case actionTypes.PATIENT_ANSWER_CALL_ERROR:
    case actionTypes.PATIENT_HANG_UP_ERROR:
      console.log(action.error);
      return Object.assign({}, state, { error: action.error });

    default:
      return state;
  }
}
